<template>
  <div class="vipMain">
    <div class="backHome" v-if="toolsShow == true">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      找到对象了
    </div>
    <div class="content">
      <div class="flex flex__middle" v-if="userCode !=''">
        <div>
          <van-image round width="4rem" height="4rem" :src="imgUrl" />
        </div>
        <div class="ml-10">{{userCodeServer}}</div>
      </div>
      <div class="text mt-10" v-if="userCode ==''">

        <!--<van-cell-group> -->
        <div style="float:left;margin-bottom: 10px;">
          <van-field v-model="queryUserCode" rows="1" ref="queryUserCode" placeholder="请输入账号" />
        </div>
        <div style="float:left;margin-top: 10px;">
          <span @click="queryUser()">查询</span>
        </div>
        <!--</van-cell-group> -->

      </div>
      <div class="text mt-10">
        <van-field v-model="message" rows="6" ref="message" autosize type="textarea" placeholder="请写上您要说的话" />
      </div>
      <div class="flex flex__justify" style="float:left;margin-top: 20px;">
        <div> 请选择您的对象,然后点击按钮,对方收到信息后如果也确认,女方直接扣除手续费后全额退款，
          男方收取1000元交友费用。但男方若有积分，可以使用积分抵扣交友费用，
          最高抵扣1000元，即男方最多退2000-手续费金额；若男方没有积分，
          则男方退1000-手续费金额。
          <!--          {{giftTitle}} -->
        </div>

      </div>
      <div class="btn" @click="buy()">我确认选了你</div>
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  export default {

    props: {},
    data() {
      return {
        name: "",
        userCode: "",
        giftCode: "",
        giftTitle: "", //加了一个现实，显示礼物名字和价格，只是展示让顾客看明白
        queryUserCode: '', //要查询的会员账号
        message: "",
        userCodeServer: "", //注意，这个是接口返回的，代表查询到了会员，并且服务器session有记录
        userName: "",
        userHeadImg: '',
        toolsShow: true, //显示工具栏

      };
    },
    components: {},
    mounted() {
      this.getWxConfig(); //加载js
      //加载用户的头像和名字
      var url = location.href;
      this.userCode = decodeURI(getQueryVariable(url,
        "userCode"));
      this.giftCode = getQueryVariable(url,
        "giftCode");
      if (this.userCode == null) {
        this.userCode == "";
      }

      this.giftTitle = '$' + getQueryVariable(url,
        "giftTitle");

      this.deviceType = getQueryVariable(url,
        "deviceType");
      if (this.deviceType == "pc") {
        //如果是pc，那么就是电脑调用，不显示对应的顶部导航
        this.toolsShow = false;
      }



      console.log("================>" + this.userCode);
      //加载这个会员的详情
      if (this.userCode == "" ||
        this.userCode == null) {
        this.userCodeServer = "请查询您要赠送的会员"
        //that.userHeadImg = result.avatar
      } else {
        this.getUserInfo(this.userCode);
      }

    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      back(where) {
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        } else {
          this.$router.back();
        }
        // this.$router.push(-1);
      },
      async getUserInfo(userCode) {
        //获取指定会员部分信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getLikeUserInfo2021",
          user: Cookies.get("kh"),
          queryUser: userCode, //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.userCodeServer = result.kh
          that.userName = result.name
          that.userHeadImg = result.avatar
          that.userCode = result.kh; //搜索界面隐藏
        } else {
          that.userCode = ""; //搜索界面隐藏
        }
      },
      async buy() {
        //购买并且赠送

        if (this.userCode == "") {
          Toast("请选择您确认的用户");
          return;
        }

        //给对方发一个消息,对方收到后可以确认,确认后这边按钮变为退费按钮

        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "confirm_friend",
          user: Cookies.get("kh"), //发起人
          type: 'friend', //类型 有friend house jobs
          message: this.$refs.message.value, //赠言
          user_other: this.userCode, //确认人
          sitenum: '18777777777',
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        //console.log(result);
        if (result.status == "1") {
          Toast(result.rsg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :1秒后延迟后退2步，第一步是选择礼品的，第二步才是用户发起界面
            if (this.deviceType == "pc") {
              //如果是pc，那么就是电脑用，千万不要退两步，退多了聊天页面都没了
              this.$router.back(); //后退+刷新；
            } else {
              this.$router.go(-2); //后退+刷新；
            }
            //延迟时间：3秒
          }, 1000)

        } else {
          Toast(result.rsg);
        }
      },
      async queryUser() {
        //用户搜索会员进行送礼
        this.$router.push({
          name: "select_user",
        });
        // this.getUserInfo(this.queryUserCode);
        // this.queryUserCode = "";

      },

      // end
    },
    computed: {
      //给头像传值
      imgUrl: function() {
        return this.userHeadImg;
      }
    },
    watch: {},
  };
</script>

<style lang="less">
  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  .content {
    margin-left: 15px;
    margin-right: 15px;

    .btn {
      width: 92%;
      height: 52px;
      background: #fd4c54;
      border-radius: 30px;
      text-align: center;
      line-height: 52px;
      color: #fff;
      font-size: 18px;
      position: fixed;
      bottom: 10px;
    }
  }

  .text {
    .van-cell {
      background-color: #f5f5ff;
      border-radius: 30px;
    }
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
